import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ButtonComponent } from '../Buttons/ButtonComponent';
import { CardRightArrow } from '../../../assets/svg';
import { useSelector } from 'react-redux';
import { sidebarOptions } from './constant';
import SidebarItem from '../Popovers/SidebarItem';
import useWindowSize from '../../../hooks/useWindowSize';

export function SideBar({ sidebarFor, currentDashboardRoute, setCurrentDashboardRoute }) {
    const [show_sidebar, toggleSidebar] = useState(false);
    const [accordionOpen, setAccordionOpen] = useState(false);
    const role = useSelector((s) => s?.user?.role);
    const isUserPrimary = useSelector((s) => s.accountArray.isUserPrimary);
    const { width } = useWindowSize();

    const clickHandler = () => {
        toggleSidebar(!show_sidebar);
    };

    return (
        <>
            <ButtonComponent
                className={`ssc-slider-btn position-absolute d-flex align-items-center justify-content-center d-lg-none d-block  bg-white p-1 ${show_sidebar ? 'open' : ''}`}
                text={''}
                type={'button'}
                id={'toggleButton'}
                clickHandler={clickHandler}
                Digital
                Document
                Library
            >
                {show_sidebar ? (
                    <CardRightArrow
                        size={14}
                        style={{ transform: 'rotate(180deg)' }}
                        stroke='var(--col-dark)'
                    />
                ) : (
                    <CardRightArrow size={14} stroke='var(--col-dark)' />
                )}
            </ButtonComponent>
            <div
                className={`col-lg-2 ssc-sliderbar ${show_sidebar ? 'show' : ''}`}
                id='MenuSidebarMobileView2'
            >
                <ul className='link-area mb-0 ps-0'>
                    {sidebarOptions(sidebarFor).map((option, index) => {
                        if (option.name === 'Digital Document Library') {
                            if (width <= 991 && !show_sidebar) {
                                return (
                                    <SidebarItem
                                        key={index}
                                        index={index}
                                        option={option}
                                        sidebarFor={sidebarFor}
                                        isUserPrimary={isUserPrimary}
                                        currentDashboardRoute={currentDashboardRoute}
                                        setCurrentDashboardRoute={setCurrentDashboardRoute}
                                    />
                                );
                            } else {
                                return (
                                    <li className={`links-set`} key={index}>
                                        <div
                                            className='d-flex align-items-center justify-content-start link-line'
                                            onClick={() => setAccordionOpen(!accordionOpen)}
                                            style={{ cursor: 'pointer' }}
                                            title={option.name}
                                        >
                                            <option.icon color='#738DA6' height={16} width={16} />
                                            <div
                                                style={{ color: 'var(--col-sidebar-text)' }}
                                                className='sidebar-ellipses'
                                            >
                                                {option.name}
                                            </div>
                                            <i className={accordionOpen ? 'rotate-svg-90' : ''}>
                                                <CardRightArrow />
                                            </i>
                                        </div>
                                        {accordionOpen && (
                                            <ul
                                                className='links-set ps-4'
                                                style={{
                                                    listStyle: 'none',
                                                }}
                                            >
                                                {option.subOptions.map((subOption, subIndex) => {
                                                    if (
                                                        sidebarFor !== 'admin' &&
                                                        subOption.name === 'Safety Meetings' &&
                                                        !isUserPrimary
                                                    ) {
                                                        return null;
                                                    }
                                                    return (
                                                        <li key={subIndex} className='links-set'>
                                                            <Link
                                                                onClick={() => {
                                                                    setCurrentDashboardRoute(
                                                                        subOption.route[0],
                                                                    );
                                                                }}
                                                                to={subOption.route[0]}
                                                                className={`d-flex align-items-center justify-content-start link-line ${
                                                                    subOption.route.includes(
                                                                        currentDashboardRoute,
                                                                    ) && 'active'
                                                                }`}
                                                                title={subOption.name}
                                                            >
                                                                <subOption.icon
                                                                    color='#738DA6'
                                                                    height={16}
                                                                    width={16}
                                                                />
                                                                <span className='sidebar-ellipses'>
                                                                    {subOption.name}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </li>
                                );
                            }
                        }

                        return sidebarFor === 'admin' ? (
                            option?.allowedRoles.includes(role) ? (
                                <li className='links-set' key={index}>
                                    <Link
                                        onClick={() => {
                                            setCurrentDashboardRoute(option.route[0]);
                                        }}
                                        to={option.route[0]}
                                        className={`d-flex align-items-center justify-content-start link-line ${option.route.includes(currentDashboardRoute) && 'active'}`}
                                        title={option.name}
                                    >
                                        <option.icon color='#738DA6' height={16} width={16} />
                                        <span>{option.name}</span>
                                    </Link>
                                </li>
                            ) : null
                        ) : (
                            <li className='links-set' key={index}>
                                <Link
                                    onClick={() => {
                                        setCurrentDashboardRoute(option.route[0]);
                                    }}
                                    to={option.route[0]}
                                    className={`d-flex align-items-center justify-content-start link-line ${option.route.includes(currentDashboardRoute) && 'active'}`}
                                    title={option.name}
                                >
                                    <option.icon color='#738DA6' height={16} width={16} />
                                    <span>{option.name}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

SideBar.propTypes = {
    sidebarFor: PropTypes.string.isRequired,
    currentDashboardRoute: PropTypes.string.isRequired,
    setCurrentDashboardRoute: PropTypes.func.isRequired,
};
