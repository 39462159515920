import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from '../../../hooks';
import { DeleteButton, ConfirmationBox } from '../../shared';
import { AUTH, INFO, ROLES, USER } from '../../../constants';
import {
    getAllUser,
    popup,
    createAccountUser,
    deleteAccountUser,
    impersonateUser,
} from '../../../utils';
import { CreateUser } from './CreateUser';
import { Delete, Edit, IconPlus, ImpersonateUserIcon } from '../../../assets/svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { formatDate } from '../../../utils/common/formatHelpers';
import useWindowSize from '../../../hooks/useWindowSize';
import SSCSelect from '../../shared/SSCSelect';

const TABLE_HEADER = ['#', 'Name', 'Email', 'Last LoggedIn', 'Type', 'Action'];

export const ManageUserBox = ({ data, setReload }) => {
    const dispatch = useDispatch();
    const isUserAdmin = useSelector((s) => s?.user?.isUserAdmin);
    const role = useSelector((s) => s?.user?.role);
    const [editUser, setEditUser] = useState(false);
    const [newUser, setNewUser] = useState(false);
    const [selectUser, setSelectedUser] = useState('');
    const [addNewUser, setAddNewUser] = useState('');
    const [searchUser, setSearchUser] = useState('');
    const [users, setUsers] = useState(data?.account?.users || []);
    const [response, setResponse] = useState([]);
    const [checkedUsers, setCheckedUsers] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const [primaryUser, setPrimaryUser] = useState(
        users.find((user) => user.is_primary === 1) || null,
    );
    const [changesMade, setChangesMade] = useState(false);
    const { width } = useWindowSize();
    const isSmallScreen = width < 720;
    const debouncedApiCall = useDebounce(() => {
        getAllUser({
            offset: 1,
            limit: 50,
            search: searchUser,
        })
            .then((res) => {
                if (res?.data) setResponse(res.data);
            })
            .catch((error) => {
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        debouncedApiCall();
    }, [searchUser]);

    const handleAddSelectedUser = (user) => {
        if (user) {
            if (users.some((u) => u.id === user.id)) {
                popup('error', USER.USER_ALREADY_EXIST);
            } else {
                setUsers((prevUsers) => [...prevUsers, user]);
                setChangesMade(true);
            }
        }
    };

    useEffect(() => {
        if (addNewUser) {
            handleAddSelectedUser(addNewUser);
            setAddNewUser('');
        } else if (selectUser) {
            handleAddSelectedUser(selectUser);
            setSelectedUser('');
        }
    }, [addNewUser, selectUser]);

    const handleSetPrimary = async () => {
        if (checkedUsers.length === 1 && primaryUser?.id !== checkedUsers[0].id) {
            setPrimaryUser(checkedUsers[0]);
            setUsers(
                users.map((u) => ({
                    ...u,
                    is_primary: u.id === checkedUsers[0].id ? 1 : 0,
                })),
            );
            setCheckedUsers([]);
            setChangesMade(true);
        }
    };

    const handleDeleteUser = () => {
        setUsers(users.filter((u) => !checkedUsers.some((cu) => cu.id === u.id)));
        setCheckedUsers([]);
        setChangesMade(true);
    };

    const handleCheckboxChange = (user) => {
        if (checkedUsers.some((cu) => cu.id === user.id)) {
            setCheckedUsers(checkedUsers.filter((cu) => cu.id !== user.id));
        } else {
            setCheckedUsers([...checkedUsers, user]);
        }
    };

    const handleSaveChanges = () => {
        setOpenConfirmationBox(true);
    };

    const saveData = () => {
        if (users.length) {
            const new_users = users.filter(
                (user) => !data.account.users.some((u) => u.id === user.id),
            );
            const body = users.map((user) => ({
                account_id: data.account.account_id,
                user_id: user.id,
                is_primary: user.id === primaryUser?.id ? 1 : 0,
            }));
            createAccountUser({
                users: body,
                new_users: new_users.map((user) => ({
                    ...user,
                    account_id: data.account.account_id,
                    account_name: data?.account?.name,
                })),
            })
                .then(() => {
                    popup('success', USER.USERS_UPDATED);
                    setConfirm(false);
                    setUsers(
                        users.map((user) => ({
                            ...user,
                            isNew: false,
                        })),
                    );
                    setReload(true);
                    setChangesMade(false);
                    setEditUser(false);
                })
                .catch((error) => {
                    popup('error', error.message);
                });
        } else if (users.length === 0) {
            const body = {
                account_id: data.account.account_id,
            };
            deleteAccountUser({ data: body })
                .then(() => {
                    popup('success', USER.USERS_DELETED);
                    setChangesMade(false);
                    setEditUser(false);
                    setReload(true);
                })
                .catch((error) => {
                    popup('error', error.message);
                });
        }
    };

    useEffect(() => {
        if (confirm) {
            saveData();
        }
    }, [confirm]);

    const handleEdit = () => {
        setEditUser(true);
    };
    const handleClose = () => {
        setUsers(data.account.users ? data.account.users : []); // reset the users to initial state
        setEditUser(false);
        setNewUser(false);
        setChangesMade(false);
        setCheckedUsers([]);
    };

    const impersonateHandler = async (user) => {
        if (isUserAdmin) {
            try {
                dispatch(setLoaderVisibility(true));
                const { data = {} } = await impersonateUser({
                    userId: user.id,
                });
                const queryString = new URLSearchParams(data).toString();
                // eslint-disable-next-line no-undef
                const impersonateBaseUrl = process.env.REACT_APP_CUSTOMER_PORTAL_LINK;
                window.open(`${impersonateBaseUrl}/customer/dashboard?${queryString}`, '_blank');
            } catch (error) {
                popup('error', error.message);
            } finally {
                dispatch(setLoaderVisibility(false));
            }
        } else {
            popup('error', 'Only an admin/csm can impersonate to user');
        }
    };

    const removeUser = (id) => {
        if (id) {
            setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
            setChangesMade(true);
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <div className='ps-lg-3 d-flex flex-column'>
                    <div className='tabs-set'>
                        <div className='d-flex justify-content-end '>
                            <div style={{ height: '14px' }}>
                                {(role === ROLES.ADMIN || role === ROLES.CONTENT_MANAGER) &&
                                    !editUser && (
                                        <span title='Edit' id='action-btn' onClick={handleEdit}>
                                            <Edit className='cursor-pointer' />
                                        </span>
                                    )}
                            </div>
                        </div>
                        <div className={`h-auto ${editUser ? 'hollow-box' : ''} mt-4`}>
                            {editUser && (
                                <div
                                    className={`wrapper position-relative d-flex flex-column align-items-start`}
                                    id='wrapper'
                                >
                                    <div className='w-100 d-flex'>
                                        <div className='w-100 d-flex gap-3 align-items-center justify-content-start flex-wrap flex-grow-1 p-2 p-md-3'>
                                            <div className={isSmallScreen ? 'w-75' : 'w-50'}>
                                                <SSCSelect
                                                    placeholder={'Select Existing User'}
                                                    options={response?.map((user) => ({
                                                        value: user,
                                                        label: user.full_name,
                                                    }))}
                                                    onInputChange={setSearchUser}
                                                    onChange={(d) => setSelectedUser(d?.value)}
                                                    isClearable
                                                />
                                            </div>
                                            {isSmallScreen ? (
                                                <span
                                                    id='action-btn-green'
                                                    title={'Add New User'}
                                                    onClick={() => setNewUser(!newUser)}
                                                >
                                                    <IconPlus />
                                                </span>
                                            ) : (
                                                <span className='flex h-100 ms-3 align-align-items-center pt-1'>
                                                    <button
                                                        className='ssc-primary-green-btn button-green-filled mt-0 mb-0'
                                                        onClick={() => setNewUser(!newUser)}
                                                    >
                                                        Add New User
                                                    </button>
                                                </span>
                                            )}
                                        </div>
                                        <div className='me-3 d-flex align-items-center justify-content-center'>
                                            <button
                                                type='button'
                                                className='btn-close'
                                                data-bs-dismiss='modal'
                                                aria-label='Close'
                                                onClick={handleClose}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {editUser && users.length !== 0 && checkedUsers.length > 0 && (
                                <div
                                    className='mt-2 input-set w-100 d-flex justify-content-end align-items-center gap-3'
                                    style={{ height: '40px' }}
                                >
                                    <DeleteButton
                                        handleClick={handleDeleteUser}
                                        width={20}
                                        height={20}
                                    />
                                    <button
                                        className='ssc-primary-green-btn button-green-filled me-3'
                                        onClick={handleSetPrimary}
                                        disabled={
                                            checkedUsers.length !== 1 ||
                                            (checkedUsers.length === 1 &&
                                                checkedUsers[0].is_primary === 1)
                                        }
                                    >
                                        {USER.SET_AS_PRIMARY_USER}
                                    </button>
                                </div>
                            )}
                            <div className='scroll-create-user mt-3 flex-fill flex-grow-1'>
                                {newUser && (
                                    <CreateUser
                                        setAddNewUser={setAddNewUser}
                                        setNewUser={setNewUser}
                                    />
                                )}
                                {users?.length === 0 && !newUser && (
                                    <div
                                        className='mt-4 vh-50 d-flex justify-content-center'
                                        style={{ fontSize: '16px' }}
                                    >
                                        {INFO.NO_USER_FOUND}
                                    </div>
                                )}
                                {users?.length > 0 && (
                                    <table className='table'>
                                        <thead
                                            className='position-sticky'
                                            style={{ top: 0, zIndex: 99 }}
                                        >
                                            <tr>
                                                {TABLE_HEADER.map((h, i) => {
                                                    return (
                                                        <th
                                                            key={i}
                                                            className={`fw-normal lh-1 text-secondary pb-2 px-2 text-nowrap + ${i === 1 && ' sticky-th-left left-neg-16 '}`}
                                                            style={{ fontSize: 13 }}
                                                        >
                                                            {h}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {users
                                                .filter((user) => user.code !== ROLES.CSM)
                                                .map((user, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {editUser ? (
                                                                <input
                                                                    type='checkbox'
                                                                    className='cursor-pointer'
                                                                    checked={checkedUsers.some(
                                                                        (cu) => cu.id === user.id,
                                                                    )}
                                                                    onChange={() =>
                                                                        handleCheckboxChange(user)
                                                                    }
                                                                />
                                                            ) : (
                                                                <span>{index + 1}.</span>
                                                            )}
                                                        </td>
                                                        <td
                                                            className='sticky-td-left text-nowrap'
                                                            style={{ left: '-16px' }}
                                                        >
                                                            {user.full_name}
                                                        </td>
                                                        <td>{user.email}</td>
                                                        <td>
                                                            {formatDate(user?.last_logged_in_at)}
                                                        </td>
                                                        <td>
                                                            {user.is_primary === 1
                                                                ? USER.PRIMARY_USER_BUTTON
                                                                : USER.SECONDARY_USER}
                                                        </td>
                                                        {!editUser && (
                                                            <td>
                                                                <span
                                                                    title={'Impersonate user'}
                                                                    id='action-btn'
                                                                    onClick={() =>
                                                                        impersonateHandler(user)
                                                                    }
                                                                    aria-label={`Impersonate ${user.full_name}`}
                                                                >
                                                                    <ImpersonateUserIcon />
                                                                </span>
                                                            </td>
                                                        )}
                                                        {editUser && (
                                                            <td>
                                                                <span
                                                                    title='Remove'
                                                                    id='action-btn'
                                                                    onClick={() =>
                                                                        removeUser(user?.id)
                                                                    }
                                                                    aria-label={`Remove ${user.full_name}`}
                                                                >
                                                                    <Delete />
                                                                </span>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                )}
                                {openConfirmationBox && (
                                    <ConfirmationBox
                                        setConfirm={setConfirm}
                                        openConfirmationBox={openConfirmationBox}
                                        setOpenConfirmationBox={setOpenConfirmationBox}
                                        customMessage={USER.ARE_YOU_SURE}
                                    />
                                )}
                            </div>
                            {changesMade && (
                                <div className='ms-auto m-4 d-flex justify-content-end '>
                                    <button
                                        className='save-change-button border-0'
                                        onClick={handleSaveChanges}
                                    >
                                        {AUTH.SAVE_CHANGES}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ManageUserBox.propTypes = {
    data: PropTypes.object,
    setReload: PropTypes.func,
};
