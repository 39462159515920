import React, { useEffect, useState } from 'react';
import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { SAFETY_MEETING, SAFETY_MEETING_ASSOCIATION_COLUMN } from '../../../constants';
import { AddSafetyMeetingModal } from '../../shared/Modal/AddSafetyMeetingModal';
import { AssignToUserIcon, GreenPlus } from '../../../assets/svg';
import AssignSafetyMeetingModal from '../../shared/Modal/AssignSafetyMeetingModal';

import { useDebounce, usePagination } from '../../../hooks';
import { popup } from '../../../utils';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { getAllSafetyMeetingAssociationService } from '../../../utils/apiServices/safetyServiceAPI';
import { Table } from '../../shared';
import useWindowSize from '../../../hooks/useWindowSize';

const SafetyMeetings = () => {
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [safetyMeetingAssociation, setSafetyMeetingAssociation] = useState(undefined);
    const pagination = usePagination(
        safetyMeetingAssociation?.data.length,
        safetyMeetingAssociation?.count,
        50,
    );
    const { width } = useWindowSize();

    const getAllSafetyMeetingAssociations = useDebounce(async () => {
        try {
            const response = await getAllSafetyMeetingAssociationService({
                offset: pagination.offset,
                limit: pagination.limit,
                search: search,
            });
            if (response.data)
                setSafetyMeetingAssociation({
                    data: response.data,
                    count: response.count,
                });
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        getAllSafetyMeetingAssociations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, pagination.offset, pagination.limit]);

    return (
        <>
            <AdminPageHeader
                showAddButton
                heading={SAFETY_MEETING.PAGE_HEADING}
                buttonText={`Add ${width > 720 ? 'Safety' : ''} Meeting`}
                handleAddClick={() => setShowAddModal(true)}
                AddModal={
                    <AddSafetyMeetingModal
                        showAddModal={showAddModal}
                        setShowAddModal={setShowAddModal}
                    />
                }
                backbutton
                showSearchBar
                searchBarPlaceholder={SAFETY_MEETING.FILE_NAME_SEARCH}
                count={pagination.dataCount}
                setSearch={setSearch}
                headerButtonChildren={
                    width > 575 ? (
                        <button
                            className='ssc-secondary-white-btn'
                            type='button'
                            data-bs-toggle='modal'
                            data-bs-target='#exampleModal-add'
                            onClick={() => setShowAssignModal(true)}
                            title='Assign Safety Meeting'
                        >
                            <GreenPlus />
                            <p>{`Assign ${width > 720 ? 'Safety' : ''} Meeting`}</p>
                        </button>
                    ) : (
                        <span
                            id='action-btn-green'
                            title='Assign Safety Meeting'
                            onClick={() => setShowAssignModal(true)}
                        >
                            <AssignToUserIcon
                                color='var(--col-btn-primary)'
                                fill={'#fff'}
                                size={20}
                            />
                        </span>
                    )
                }
            />
            {!!safetyMeetingAssociation && (
                <Table
                    columns={SAFETY_MEETING_ASSOCIATION_COLUMN}
                    data={safetyMeetingAssociation?.data}
                    pagination={pagination}
                    pageName='Safety Meeting Associations'
                    debouncedApiCall={getAllSafetyMeetingAssociations}
                    stickyColumns={['Client']}
                />
            )}
            {showAssignModal && (
                <AssignSafetyMeetingModal
                    showModal={showAssignModal}
                    setShowModal={setShowAssignModal}
                    getAllSafetyMeetingAssociations={getAllSafetyMeetingAssociations}
                />
            )}
        </>
    );
};

export default SafetyMeetings;
