import React, { useEffect, useState } from 'react';
import { AdminPageHeader } from '../../Admin';
import { Table } from '../../shared';
import { setLoaderVisibility } from '../../../redux';
import { getAllDigitalDeliveryForUser, getLocalStorageItem } from '../../../utils';
import { useDebounce, usePagination } from '../../../hooks';
import { useDispatch } from 'react-redux';
import {
    DIGITAL_SAFETY_LIBRARY,
    DIGITAL_SAFETY_LIBRARY_CUSTOMER_COLUMNS,
    LOCAL_STORAGE,
} from '../../../constants';

export const DigitalSafetyLibraryCustomer = () => {
    const dispatch = useDispatch();
    const [response, setResponse] = useState({
        data: [],
        count: 1,
    });
    const currentUserId = getLocalStorageItem(LOCAL_STORAGE.USER_ID);
    const customerId = getLocalStorageItem(LOCAL_STORAGE.CUSTOMER_ID);
    const pagination = usePagination(response.data.length, response.count);
    const [search, setSearch] = useState('');

    const debouncedApiCall = useDebounce(async () => {
        try {
            const res = await getAllDigitalDeliveryForUser({
                id: currentUserId,
                account_id: customerId,
                limit: pagination.limit,
                offset: pagination.offset,
                search: search,
            });
            if (res?.data) setResponse({ data: res.data, count: res.count });
            dispatch(setLoaderVisibility(false));
        } catch (error) {
            dispatch(setLoaderVisibility(false));
        }
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
    }, [pagination.limit, pagination.offset, search, customerId]);

    return (
        <>
            <AdminPageHeader
                heading={DIGITAL_SAFETY_LIBRARY.PAGE_HEADING}
                backbutton
                showSearchBar
                searchBarPlaceholder={DIGITAL_SAFETY_LIBRARY.FILE_NAME_SEARCH}
                count={pagination.dataCount}
                setSearch={setSearch}
            />

            <Table
                columns={DIGITAL_SAFETY_LIBRARY_CUSTOMER_COLUMNS}
                data={response.data}
                pagination={pagination}
                pageName={DIGITAL_SAFETY_LIBRARY.PAGE_HEADING}
                debouncedApiCall={debouncedApiCall}
                stickyColumns={['File Name']}
            />
        </>
    );
};
