import { OVERVIEW, ROLE_NAME, ROLES } from './strings';

export const INDUSTRIES = {
    CONSTRUCTION: 'Construction',
    MANUFACTURING: 'Manufacturing',
    RESTAURANT: 'Restaurant',
    HOSPITALITY: 'Hospitality',
    QUIZ: 'Quiz',
    OTHER: 'Other',
};

export const LANGUAGES = {
    ENGLISH: 'English',
    SPANISH: 'Spanish',
    CANADA: 'Canada',
};

export const REQUEST_STATUS = [
    { id: '1', name: 'Approved', value: 'approved' },
    { id: '2', name: 'Pending', value: 'pending' },
    { id: '3', name: 'Requested', value: 'requested' },
    { id: '4', name: 'Rejected', value: 'rejected' },
];

export const registrationAndDocumentDropdownOptions = [
    { id: '1', value: 30, name: OVERVIEW.DAYS_30 },
    { id: '2', value: 60, name: OVERVIEW.DAYS_60 },
    { id: '3', value: 90, name: OVERVIEW.DAYS_90 },
    { id: '4', value: 120, name: OVERVIEW.DAYS_120 },
];

export const currentRoleOptions = [
    { id: 1, value: ROLES.ADMIN, name: ROLE_NAME.ADMIN },
    { id: 2, value: ROLES.CONTENT_MANAGER, name: ROLE_NAME.CONTENT_MANAGER },
    { id: 3, value: ROLES.CSM, name: ROLE_NAME.CSM },
];

export const industryTypesOptions = [
    { id: 1, value: INDUSTRIES.CONSTRUCTION, label: INDUSTRIES.CONSTRUCTION },
    { id: 2, value: INDUSTRIES.MANUFACTURING, label: INDUSTRIES.MANUFACTURING },
    { id: 3, value: INDUSTRIES.RESTAURANT, label: INDUSTRIES.RESTAURANT },
    { id: 4, value: INDUSTRIES.HOSPITALITY, label: INDUSTRIES.HOSPITALITY },
    { id: 4, value: INDUSTRIES.QUIZ, label: INDUSTRIES.QUIZ },
    { id: 5, value: INDUSTRIES.OTHER, label: INDUSTRIES.OTHER },
];

export const safetyMeetingTypesOptions = [
    { id: 1, value: INDUSTRIES.CONSTRUCTION, label: INDUSTRIES.CONSTRUCTION },
    { id: 2, value: INDUSTRIES.MANUFACTURING, label: INDUSTRIES.MANUFACTURING },
    { id: 3, value: INDUSTRIES.RESTAURANT, label: INDUSTRIES.RESTAURANT },
    { id: 5, value: INDUSTRIES.OTHER, label: INDUSTRIES.OTHER },
];

export const languageOptions = [
    { id: 1, value: LANGUAGES.ENGLISH, label: LANGUAGES.ENGLISH },
    { id: 2, value: LANGUAGES.SPANISH, label: LANGUAGES.SPANISH },
    { id: 3, value: LANGUAGES.CANADA, label: LANGUAGES.CANADA },
];

export const monthOptions = [
    { name: 'Jan', fullName: 'January' },
    { name: 'Feb', fullName: 'February' },
    { name: 'Mar', fullName: 'March' },
    { name: 'Apr', fullName: 'April' },
    { name: 'May', fullName: 'May' },
    { name: 'Jun', fullName: 'June' },
    { name: 'Jul', fullName: 'July' },
    { name: 'Aug', fullName: 'August' },
    { name: 'Sep', fullName: 'September' },
    { name: 'Oct', fullName: 'October' },
    { name: 'Nov', fullName: 'November' },
    { name: 'Dec', fullName: 'December' },
];
