import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { setLoaderVisibility } from '../../../redux';
import { CSM_COLUMNS, CSM } from '../../../constants';
import { usePagination, useDebounce } from '../../../hooks';
import { popup, getAllCSM } from '../../../utils';
import { Table } from '../../shared';

export const CsmListing = () => {
    const dispatch = useDispatch();
    const [response, setResponse] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState('');
    const pagination = usePagination(response.data.length, response.count);

    const debouncedApiCall = useDebounce(() => {
        getAllCSM({
            limit: pagination.limit,
            offset: pagination.offset,
            search: search,
        })
            .then((res) => {
                if (res?.data) setResponse({ data: res.data, count: res.count });
                dispatch(setLoaderVisibility(false));
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
    }, [pagination.limit, pagination.offset, search]);

    return (
        <>
            <AdminPageHeader
                heading={CSM.PAGE_HEADING}
                buttonText={CSM.BUTTON_TEXT}
                searchBarPlaceholder={CSM.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                setCurrentPage={pagination.setCurrentPage}
                backbutton
            />

            <Table
                columns={CSM_COLUMNS}
                data={response.data}
                pagination={pagination}
                pageName={CSM.PAGE_NAME}
                stickyColumns={['CSM Name']}
            />
        </>
    );
};
