import React, { useEffect, useState } from 'react';
import { SAFETY_MEETINGS_COLUMN } from '../../../constants';
import { useDebounce, usePagination } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { resetSafetyMeetingStore, setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { Table } from '../Table';
import { SearchIcon } from '../../../assets/svg';
import {
    createSafetyMeetingAssociationService,
    getAllSafetyMeetingService,
} from '../../../utils/apiServices/safetyServiceAPI';
import { useSelector } from 'react-redux';
import SelectCustomerDD from '../../Admin/SafetyMeetings/SelectCustomerDD';
import { Button, Modal } from 'react-bootstrap';

const AssignSafetyMeetingModal = ({ showModal, setShowModal, getAllSafetyMeetingAssociations }) => {
    const [safetyMeetings, setSafetyMeetings] = useState(undefined);
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const selectedSafetyMeetings = useSelector((s) => s.safetyMeetings.selectedSafetyMeetings);
    const safetyMeetingCustomer = useSelector((s) => s.safetyMeetings.safetyMeetingCustomer);
    const pagination = usePagination(safetyMeetings?.data.length, safetyMeetings?.count, 50);

    const getAllSafetyMeetings = useDebounce(async () => {
        try {
            const response = await getAllSafetyMeetingService({
                offset: pagination.offset,
                limit: pagination.limit,
                search: search,
            });
            setSafetyMeetings({
                data: response.data,
                count: response.count,
            });
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 500);

    useEffect(() => {
        getAllSafetyMeetings();
    }, [search, pagination.offset, pagination.limit]);

    const assignedHandler = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            if (selectedSafetyMeetings.length > 0 && safetyMeetingCustomer?.id) {
                const data = {
                    meeting_ids: selectedSafetyMeetings.map((item) => item.id),
                    account_id: safetyMeetingCustomer.id,
                    primary_user_id: safetyMeetingCustomer.primaryUserId,
                };

                const res = await createSafetyMeetingAssociationService({ data });
                if (res?.status === 'success') {
                    popup('success', res.message + `: ${safetyMeetingCustomer.name}`);
                    dispatch(resetSafetyMeetingStore());
                    setShowModal(false);
                    await getAllSafetyMeetingAssociations();
                }
            }
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    return (
        <Modal
            show={showModal}
            onHide={() => {
                dispatch(resetSafetyMeetingStore());
                setShowModal(false);
            }}
            size='xl'
            dialogClassName='overflow-hidden'
            fullscreen='lg-down'
        >
            <Modal.Header closeButton>
                <Modal.Title className='me-2'>Assign Safety Meetings</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ maxHeight: '720px' }}>
                <div className='align-items-center d-flex gap-2 col-12 '>
                    <SelectCustomerDD />
                    <div
                        className=' col-6 custom-search-box d-flex align-items-center justify-content-start'
                        style={{ height: '48px', marginTop: '1.3rem' }}
                    >
                        <button className='bg-white p-0 border-0 d-flex align-items-center'>
                            <SearchIcon />
                        </button>
                        <input
                            type='text'
                            maxLength={40}
                            className='search-area border-0 w-100 py-1'
                            placeholder={'Search Safety Meetings...'}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                <div className='modal-body p-0 pt-4'>
                    {!!safetyMeetings && (
                        <Table
                            columns={SAFETY_MEETINGS_COLUMN}
                            data={safetyMeetings?.data}
                            pagination={pagination}
                            tableStyle={{ height: '500px', overflow: 'auto' }}
                            pageName='safetyMeeting(s)'
                            debouncedApiCall={getAllSafetyMeetings}
                            stickyColumns={[0, 'File Name']}
                        />
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex w-100 align-items-center justify-content-between modal-btn'>
                    <span
                        style={{ color: '#738da6', fontSize: '14px' }}
                    >{`${selectedSafetyMeetings.length} selected`}</span>
                    <Button
                        type='button'
                        onClick={() => assignedHandler()}
                        className='table-footer-btn'
                        disabled={
                            selectedSafetyMeetings.length === 0 ||
                            !Object.keys(safetyMeetingCustomer).length
                        }
                    >
                        Assign
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignSafetyMeetingModal;
