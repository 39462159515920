import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RequestBoxContainer } from './RequestBoxContainer';
import { ButtonComponent } from '../../shared';
import DocumentRequestSubmit from './DocumentRequestSubmit';
import DocumentUploadConfirm from './DocumentUploadConfirm';
import { AUTH, BUTTONS, DOCUMENT_REQUESTS } from '../../../constants';
import { AdminPageHeader } from '../Common/AdminPagesHeader';

export const DocumentRequestView = () => {
    const location = useLocation();
    const { state } = location;
    const [accountDetails] = useState(state?.accountUserDetails);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [cancelStatus, setCancelStatus] = useState(false);
    const [res, setRes] = useState(false);
    const [confirmUpload, setConfirmUpload] = useState(0);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);

    return (
        <div id='rd-main-container'>
            <AdminPageHeader
                heading={DOCUMENT_REQUESTS.DOCUMENT_REQUEST}
                backbutton
                showSearchBar={false}
            />
            <RequestBoxContainer
                accountDetails={accountDetails}
                submitStatus={submitStatus}
                setSubmitStatus={setSubmitStatus}
                setRes={setRes}
                cancelStatus={cancelStatus}
                setCancelStatus={setCancelStatus}
                confirmUpload={confirmUpload}
                setConfirmUpload={setConfirmUpload}
                setOpenConfirmationBox={setOpenConfirmationBox}
            />
            <div className='col-12 pe-1'>
                <div className='outter-box-btns w-100 d-flex align-items-center justify-content-end'>
                    <ButtonComponent
                        className={'ssc-secondary-white-btn cancel-btn'}
                        clickHandler={() => setCancelStatus(true)}
                    >
                        {BUTTONS.CANCEL_BUTTON}
                    </ButtonComponent>
                    <ButtonComponent
                        className={'ssc-primary-green-btn submit-btn'}
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#exampleModal'
                        clickHandler={() => setSubmitStatus(true)}
                    >
                        {AUTH.SUBMIT}
                    </ButtonComponent>

                    {res && <DocumentRequestSubmit res={res} setRes={setRes} />}
                    {openConfirmationBox && (
                        <DocumentUploadConfirm
                            openConfirmationBox={openConfirmationBox}
                            setConfirmUpload={setConfirmUpload}
                            setOpenConfirmationBox={setOpenConfirmationBox}
                            setSubmitStatus={setSubmitStatus}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
