import React, { useState } from 'react';
import { Delete, ViewIcon } from '../../../assets/svg';
import AssignedSafetyMeetingModal from '../../shared/Modal/AssignedSafetyMeetingModal';
import { DeleteConfirmationModal } from '../../shared';
import { popup } from '../../../utils';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { deleteSafetyMeetingAssociationService } from '../../../utils/apiServices/safetyServiceAPI';

const SafetyMeetingAssociationActions = ({ data, debouncedApiCall }) => {
    const { account_id, account_name } = data;
    const dispatch = useDispatch();
    const [showViewModal, setShowViewModal] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const deleteHandler = async ({ selectedItemId, setIsDeleteModal }) => {
        try {
            dispatch(setLoaderVisibility(true));
            const response = await deleteSafetyMeetingAssociationService({
                accountId: selectedItemId,
            });
            popup('success', response.message);
            setIsDeleteModal(false);
            debouncedApiCall();
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    return (
        <>
            <div className='d-flex gap-2'>
                <span title={'View files'} id='action-btn' onClick={() => setShowViewModal(true)}>
                    <ViewIcon />
                </span>
                <span
                    title={'Delete all files'}
                    id='action-btn'
                    onClick={() => setIsDeleteModalOpen(true)}
                >
                    <Delete />
                </span>

                <DeleteConfirmationModal
                    isDeleteModal={isDeleteModalOpen}
                    setIsDeleteModal={setIsDeleteModalOpen}
                    selectedItemId={account_id}
                    handleDeleteClick={deleteHandler}
                    deleteButtonName={` all safety meetings assigned to ${account_name} ?`}
                />

                <AssignedSafetyMeetingModal
                    showModal={showViewModal}
                    setShowModal={setShowViewModal}
                    accountName={account_name}
                    accountId={account_id}
                    debouncedApiCall={debouncedApiCall}
                />
            </div>
        </>
    );
};

export default SafetyMeetingAssociationActions;
