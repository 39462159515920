import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SidebarItem = ({
    index,
    option,
    sidebarFor,
    isUserPrimary,
    currentDashboardRoute,
    setCurrentDashboardRoute,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => setIsExpanded((prev) => !prev);

    return (
        <>
            <li className='links-set' key={index}>
                <div
                    className='d-flex align-items-center justify-content-start link-line'
                    style={{ cursor: 'pointer', position: 'relative' }}
                    title={option.name}
                    onClick={toggleExpanded}
                >
                    <option.icon color='#738DA6' height={16} width={16} />
                </div>
            </li>

            {isExpanded && (
                <>
                    {option.subOptions.map((subOption, subIndex) => {
                        if (
                            sidebarFor !== 'admin' &&
                            subOption.name === 'Safety Meetings' &&
                            !isUserPrimary
                        ) {
                            return null;
                        }

                        return (
                            <li key={subIndex} className='links-set'>
                                <Link
                                    onClick={() => {
                                        setCurrentDashboardRoute(subOption.route[0]);
                                    }}
                                    to={subOption.route[0]}
                                    className={`d-flex align-items-center justify-content-start link-line ${
                                        subOption.route.includes(currentDashboardRoute) && 'active'
                                    }`}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'var(--col-body)',
                                    }}
                                >
                                    <subOption.icon color='#738DA6' height={16} width={16} />
                                </Link>
                            </li>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default SidebarItem;
