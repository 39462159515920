import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setLoaderVisibility } from '../../../redux';
import { useDebounce } from '../../../hooks';
import { getAccountByID, popup } from '../../../utils';
import { EDIT_ACCOUNT, EDIT_CUSTOMER, ROLES } from '../../../constants';
import { ManageUserBox } from './ManageUserBox';
import { Heading } from './Heading';
import { SectionBox } from './SectionBox';
import { PersonalInfoBox } from './PersonalInfoBox';
import { ManageCSMBox } from './ManageCSMBox';
import { ManagePlatformBox } from './ManagePlatformBox';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

export const EditCustomer = () => {
    const role = useSelector((s) => s?.user?.role);
    const [activeTab, setActiveTab] = useState(() => (role === ROLES.ADMIN ? 0 : 2));
    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
    const [id] = useState(state?.id);
    const [accountData, setAccountData] = useState({});
    const [reload, setReload] = useState(false);

    // get all data
    const debouncedApiCall = useDebounce(() => {
        getAccountByID({ id: id })
            .then((res) => {
                if (res?.data) setAccountData(res.data);
            })
            .catch((error) => {
                popup('error', error.message);
            })
            .finally(() => {
                dispatch(setLoaderVisibility(false));
            });
    }, 500);

    // reload data if id or reload state changed
    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        setReload(false);
    }, [reload]);

    // tab switch
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='edit-box-customer'>
            <Heading data={accountData || ''} heading={EDIT_CUSTOMER.HEADING} />
            <div className='tabs-set'>
                <div className='tabs-row d-flex align-items-center justify-content-start w-100 overflow-y-hidden overflow-x-auto'>
                    {EDIT_ACCOUNT.TABS_WITH_ROLES.map((tab) => {
                        return tab.allowedRoles.includes(role) ? (
                            <span
                                key={tab.name}
                                className={`d-flex align-items-center justify-content-center link-profile ${activeTab === tab.id ? 'active' : ''}`}
                                onClick={() => handleTabChange(tab.id)}
                            >
                                <span className='text-nowrap'>
                                    {role === ROLES.CSM ? tab.csm_name : tab.name}
                                </span>
                            </span>
                        ) : null;
                    })}
                </div>
                <div></div>
            </div>
            <SectionBox>
                {activeTab === 0 && !isEmpty(accountData) && (
                    <PersonalInfoBox data={accountData} debouncedApiCall={debouncedApiCall} />
                )}
                {activeTab === 1 && !isEmpty(accountData) && (
                    <ManageCSMBox data={accountData} debouncedApiCall={debouncedApiCall} />
                )}
                {activeTab === 2 && !isEmpty(accountData) && (
                    <ManageUserBox data={accountData} setReload={setReload} />
                )}
                {activeTab === 3 && !isEmpty(accountData) && (
                    <ManagePlatformBox
                        accountData={accountData}
                        debouncedApiCall={debouncedApiCall}
                    />
                )}
            </SectionBox>
        </div>
    );
};
