import React, { useEffect, useRef, useState } from 'react';
import { DownArrow, SearchIcon } from '../../../assets/svg';
import { CUSTOMER_LIST } from '../../../constants';
import { setSafetyMeetingCustomer } from '../../../redux';
import { useDebounce } from '../../../hooks';
import { getAllCustomer, popup } from '../../../utils';
import { useSelector, useDispatch } from 'react-redux';

const SelectCustomerDD = () => {
    const [openCsmDropdown, setOpenCsmDropdown] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [searchCustomer, setSearchCustomer] = useState('');
    const dropdownRef = useRef(null);
    const ddInputRef = useRef(null);
    const selectedCustomer = useSelector((s) => s.safetyMeetings.safetyMeetingCustomer);
    const dispatch = useDispatch();

    const handleClear = (e) => {
        e.stopPropagation();
        dispatch(setSafetyMeetingCustomer({}));
    };

    const handleSearchCustomer = (e) => {
        setSearchCustomer(e.target.value);
    };

    const handleClose = (data) => {
        setOpenCsmDropdown(!openCsmDropdown);
        if (!data.primaryUserId) {
            popup('error', 'Client has no primary user');
            return;
        }
        dispatch(
            setSafetyMeetingCustomer({
                id: data.id,
                name: data.name,
                primaryUserId: data.primaryUserId,
            }),
        );
    };

    const debouncedFetchData = useDebounce(() => {
        getAllCustomer({
            offset: 1,
            limit: 10,
            search: searchCustomer || '',
        })
            .then((res) => {
                if (res?.data) setCustomer(res.data);
            })
            .catch((error) => {
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        debouncedFetchData();
    }, [searchCustomer]);

    return (
        <div className='col-6'>
            <div className='input-set-area'>
                <label htmlFor=''>
                    {CUSTOMER_LIST.PAGE_HEADING}
                    <span className='text-danger ms-1' title='Required Field'>
                        *
                    </span>
                </label>
                <div
                    className={`wrapper position-relative ${openCsmDropdown ? 'active' : ''}`}
                    id='wrapper'
                    ref={dropdownRef}
                >
                    <div
                        className='select-btn'
                        id='select-btn'
                        onClick={() => {
                            setTimeout(() => {
                                ddInputRef?.current?.focus();
                            }, 50);
                            setOpenCsmDropdown(!openCsmDropdown);
                        }}
                    >
                        <span
                            className={
                                !selectedCustomer.name ? 'new-customer-input' : 'select-option-csm'
                            }
                        >
                            {selectedCustomer.name
                                ? selectedCustomer.name
                                : CUSTOMER_LIST.SEARCH_BAR_PLACEHOLDER}
                        </span>
                        <div className=''>
                            {selectedCustomer.name ? (
                                <button
                                    type='button'
                                    className='btn-close w-25 me-1'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                    onClick={handleClear}
                                />
                            ) : (
                                <DownArrow />
                            )}
                        </div>
                    </div>
                    {openCsmDropdown && (
                        <div
                            className='content position-absolute w-100 shadow-sm'
                            style={{ zIndex: 999 }}
                        >
                            <div className='search position-relative'>
                                <SearchIcon className={'search-icon-svg'} width='16' height='16' />
                                <input
                                    type='search'
                                    name=''
                                    value={searchCustomer}
                                    onChange={handleSearchCustomer}
                                    placeholder='Search'
                                    className='input-dropdown-set'
                                    ref={ddInputRef}
                                />
                            </div>
                            <ul className='options mb-0' style={{ maxHeight: 120 }}>
                                {customer.map((customer, index) => (
                                    <li
                                        key={index}
                                        onClick={() =>
                                            handleClose({
                                                id: customer.id,
                                                name: customer.name,
                                                primaryUserId: customer.primary_user_id,
                                            })
                                        }
                                    >
                                        <span
                                            className={`${!customer.primary_user_id && 'text-danger'}`}
                                            title={!customer.primary_user_id && 'No primary user'}
                                        >
                                            {customer.name}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SelectCustomerDD;
