import React, { useState } from 'react';
import { Delete, DownloadIcon, Edit, ViewIcon } from '../../../assets/svg';
import { useSelector } from 'react-redux';
import { DIGITAL_SAFETY_LIBRARY, ROLES } from '../../../constants';
import { DeleteConfirmationModal } from '../Modal/DeleteConfirmationModal';
import {
    deleteDigitalDelivery,
    getFileSignedUrl,
    popup,
    updateDigitalDelivery,
} from '../../../utils';
import { setFileViewModalDetails, setLoaderVisibility } from '../../../redux';
import { useDispatch } from 'react-redux';
import { EditFileNameModal } from '../Modal/EditFileNameModal';

const DigitalSafetyLibraryActions = ({ data, debouncedApiCall }) => {
    const { id, file_name, s3_key } = data;
    const dispatch = useDispatch();
    const currentUser = useSelector((s) => s.user);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const hasPrivileges = [
        ROLES.ADMIN,
        ROLES.CONTENT_MANAGER,
        ROLES.CSM,
        ROLES.SUPER_ADMIN,
    ].includes(currentUser.role);

    const viewHandler = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            const { data } = await getFileSignedUrl({ filePath: s3_key });
            dispatch(
                setFileViewModalDetails({
                    openFileViewerModal: true,
                    signedURL: data.url,
                    fileName: file_name,
                }),
            );
        } catch (error) {
            popup('error', error.message ?? 'File Maybe Corrupted');
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    const downloadHandler = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            const { data } = await getFileSignedUrl({ filePath: s3_key });
            const response = await fetch(data?.url); // Fetch the file
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', file_name || '');
            link.click();
        } catch (error) {
            popup('error', error.message ?? 'Unable to download the file');
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    const editHandler = () => {
        setShowEditModal(true);
    };

    const deleteHandler = async ({ selectedItemId, setIsDeleteModal }) => {
        try {
            dispatch(setLoaderVisibility(true));
            const response = await deleteDigitalDelivery({ id: selectedItemId, data: { s3_key } });
            popup('success', response.message);
            setIsDeleteModal(false);
            debouncedApiCall();
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    const editModalOnSubmit = async (values, { setSubmitting }) => {
        dispatch(setLoaderVisibility(true));
        setSubmitting(true);
        try {
            const { message } = await updateDigitalDelivery({
                data: { file_name: values.fileName },
                id,
            });
            popup('success', message);
            debouncedApiCall();
            setShowEditModal(false);
        } catch (error) {
            popup('error', error.message);
        } finally {
            setSubmitting(false);
            dispatch(setLoaderVisibility(false));
        }
    };

    return (
        <div>
            <div className='d-flex gap-2'>
                <span title={DIGITAL_SAFETY_LIBRARY.VIEW} id='action-btn' onClick={viewHandler}>
                    <ViewIcon />
                </span>
                <span
                    title={DIGITAL_SAFETY_LIBRARY.DOWNLOAD}
                    id='action-btn'
                    onClick={downloadHandler}
                >
                    <DownloadIcon />
                </span>
                {hasPrivileges && (
                    <span title={DIGITAL_SAFETY_LIBRARY.EDIT} id='action-btn' onClick={editHandler}>
                        <Edit />
                    </span>
                )}
                {hasPrivileges && (
                    <span
                        title={DIGITAL_SAFETY_LIBRARY.DELETE}
                        id='action-btn'
                        onClick={() => setIsDeleteModalOpen(true)}
                    >
                        <Delete />
                    </span>
                )}
            </div>
            {isDeleteModalOpen && hasPrivileges && (
                <DeleteConfirmationModal
                    isDeleteModal={isDeleteModalOpen}
                    setIsDeleteModal={setIsDeleteModalOpen}
                    selectedItemId={id}
                    handleDeleteClick={deleteHandler}
                    deleteButtonName={file_name}
                />
            )}
            {showEditModal && hasPrivileges && (
                <EditFileNameModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    currentFileName={file_name}
                    onSubmit={editModalOnSubmit}
                />
            )}
        </div>
    );
};

export default DigitalSafetyLibraryActions;
