import React, { useEffect, useState } from 'react';
import { ASSIGNED_SAFETY_MEETINGS_COLUMN } from '../../../constants';
import { useDebounce, usePagination } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { resetSafetyMeetingStore, setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { Table } from '../Table';
import { Delete, SearchIcon } from '../../../assets/svg';
import {
    deleteSafetyMeetingAssociationService,
    getSafetyServiceAssociationByAccountIdService,
} from '../../../utils/apiServices/safetyServiceAPI';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import YearMonthPopoverPicker from '../common/YearMonthPopoverPicker';

const AssignedSafetyMeetingModal = ({
    showModal,
    setShowModal,
    accountId,
    accountName,
    debouncedApiCall,
}) => {
    const [safetyMeetings, setSafetyMeetings] = useState(undefined);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const selectedSafetyMeetings = useSelector((s) => s.safetyMeetings.selectedSafetyMeetings);
    const [search, setSearch] = useState('');
    const [dateFilter, setDateFilter] = useState({ month: '', year: '' });
    const dispatch = useDispatch();
    const pagination = usePagination(safetyMeetings?.data.length, safetyMeetings?.count, 50);

    const getSafetyServiceAssociationByAccountId = useDebounce(async () => {
        try {
            const response = await getSafetyServiceAssociationByAccountIdService({
                offset: pagination.offset,
                limit: pagination.limit,
                search: search,
                accountId: accountId,
                year: dateFilter.year,
                month: dateFilter.month,
            });
            setSafetyMeetings({
                data: response.data,
                count: response.count,
            });
        } catch (error) {
            console.warn('error', error.message + ` for ${accountName}`);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 500);

    useEffect(() => {
        if (showModal) {
            !search && dispatch(setLoaderVisibility(true));
            getSafetyServiceAssociationByAccountId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, pagination.offset, pagination.limit, showModal, dateFilter]);

    const deleteHandler = async ({ selectedItemId, setIsDeleteModal }) => {
        try {
            dispatch(setLoaderVisibility(true));
            const response = await deleteSafetyMeetingAssociationService({
                accountId: selectedItemId,
                ids: selectedSafetyMeetings.map((item) => item.id),
            });
            popup('success', response.message);
            setIsDeleteModal(false);
            dispatch(resetSafetyMeetingStore());
            getSafetyServiceAssociationByAccountId();
            debouncedApiCall();
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    return (
        <Modal
            show={showModal}
            onHide={() => {
                dispatch(resetSafetyMeetingStore());
                setShowModal(false);
            }}
            size='xl'
            dialogClassName='overflow-hidden'
            fullscreen='lg-down'
        >
            <Modal.Header closeButton>
                <Modal.Title className='me-2'>{`${accountName}'s Safety Meetings`}</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ maxHeight: '720px', overflow: 'hidden' }}>
                <div className='align-items-center d-flex flex-wrap flex-sm-nowrap justify-content-between gap-2 col-12 '>
                    <div
                        className=' w-100 custom-search-box d-flex align-items-center justify-content-start'
                        style={{ height: '48px', marginTop: '0.5rem' }}
                    >
                        <button className='bg-white p-0 border-0 d-flex align-items-center'>
                            <SearchIcon />
                        </button>
                        <input
                            type='text'
                            maxLength={40}
                            className='search-area border-0 w-100 py-1'
                            placeholder={'Search Safety Meetings...'}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <div className='w-100 d-flex justify-content-end'>
                        <YearMonthPopoverPicker
                            onChange={(v) => {
                                if (v) {
                                    const [month, year] = v.split('/');
                                    setDateFilter({ month, year });
                                } else {
                                    setDateFilter({ month: '', year: '' });
                                }
                            }}
                            className='mt-2'
                            selectStyle={{ width: '120px' }}
                            label={'Assigned Month/Year:'}
                            title={'Filter Meetings By Month/Year'}
                        />
                    </div>
                </div>
                <div className='modal-body p-0 pt-4'>
                    {!!safetyMeetings && (
                        <Table
                            columns={ASSIGNED_SAFETY_MEETINGS_COLUMN}
                            data={safetyMeetings?.data}
                            pagination={pagination}
                            tableStyle={{ height: '56dvh', overflow: 'auto' }}
                            pageName='safetyMeeting(s)'
                            stickyColumns={[0, 'File Name']}
                        />
                    )}
                </div>
            </Modal.Body>
            {selectedSafetyMeetings.length > 0 && (
                <Modal.Footer>
                    <div
                        className={`w-100 d-flex align-items-center ${
                            selectedSafetyMeetings.length < 2
                                ? 'justify-content-end'
                                : 'justify-content-apart'
                        }`}
                    >
                        <div className=' d-flex w-100 justify-content-center align-items-center'>
                            <span
                                title={selectedSafetyMeetings.length > 1 ? 'Delete all' : 'Delete'}
                                id='action-btn'
                                onClick={() => setIsDeleteModalOpen(true)}
                                style={{ scale: '1.4' }}
                            >
                                <Delete />
                            </span>
                        </div>
                    </div>
                    <DeleteConfirmationModal
                        isDeleteModal={isDeleteModalOpen}
                        setIsDeleteModal={setIsDeleteModalOpen}
                        selectedItemId={accountId}
                        handleDeleteClick={deleteHandler}
                        deleteButtonName={`${selectedSafetyMeetings.length} Safety Meetings of ${accountName}`}
                    />
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default AssignedSafetyMeetingModal;
