import React, { useState } from 'react';
import { setLoaderVisibility, setFileViewModalDetails } from '../../../redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Delete, DownloadIcon, Edit, ReplaceIcon, ViewIcon } from '../../../assets/svg';
import { languageOptions, ROLES, TABLE_ID } from '../../../constants';
import { getFileSignedUrl, popup } from '../../../utils';
import {
    deleteSafetyMeetingService,
    updateSafetyMeetingsService,
} from '../../../utils/apiServices/safetyServiceAPI';
import { AddSafetyMeetingModal } from '../../shared/Modal/AddSafetyMeetingModal';
import { DeleteConfirmationModal } from '../../shared';
import { ReplaceSafetyMeetingModal } from '../../shared/Modal/ReplaceSafetyMeetingModal';
import useViewProcessedFile from '../../../hooks/useViewProcessedFile';
import useBulkDownloadFiles from '../../../hooks/useBulkDownloadFiles';

export const SafetyMeetingActions = ({ data, tableId, debouncedApiCall }) => {
    const { id, file_name, language, industries, s3_key } = data;
    const { viewProcessedFile } = useViewProcessedFile();
    const { batchDownloadFiles } = useBulkDownloadFiles();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showReplaceModal, setShowReplaceModal] = useState(false);
    const accountName = useSelector((s) => s.accountArray.selectedAccountName);

    const dispatch = useDispatch();
    const currentRole = useSelector((s) => s.user.role);
    const isAdmin = currentRole === ROLES.ADMIN;
    const isUser = currentRole === ROLES.USER;
    const hasPrivileges = [
        ROLES.ADMIN,
        ROLES.CONTENT_MANAGER,
        ROLES.CSM,
        ROLES.SUPER_ADMIN,
    ].includes(currentRole);

    const editModalOnSubmit = async (values, { setSubmitting }) => {
        dispatch(setLoaderVisibility(true));
        setSubmitting(true);
        try {
            const { message } = await updateSafetyMeetingsService({
                data: {
                    file_name: values.fileName,
                    industries: values.industries?.map((industry) => industry.value),
                    language: values.language?.value,
                },
                id,
            });
            popup('success', message);
            debouncedApiCall();
            setShowEditModal(false);
        } catch (error) {
            popup('error', error.message);
        } finally {
            setSubmitting(false);
            dispatch(setLoaderVisibility(false));
        }
    };

    const viewHandler = async () => {
        try {
            if (!s3_key) {
                return popup('error', 'File URL Not Found');
            }
            dispatch(setLoaderVisibility(true));
            const { data } = await getFileSignedUrl({ filePath: s3_key });
            dispatch(
                setFileViewModalDetails({
                    openFileViewerModal: true,
                    signedURL: data.url,
                    fileName: file_name,
                }),
            );
        } catch (error) {
            popup('error', error.message ?? 'File Maybe Corrupted');
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    const downloadHandler = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            const { data } = await getFileSignedUrl({ filePath: s3_key });
            const response = await fetch(data?.url); // Fetch the file
            if (response.status !== 200)
                throw new Error('Unable to download the file, File Does not Exist');
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', file_name || '');
            link.click();
        } catch (error) {
            popup('error', error.message ?? 'Unable to download the file');
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    const deleteHandler = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            const response = await deleteSafetyMeetingService({
                data: { id, s3_key },
            });
            popup('success', response.message);
            setIsDeleteModalOpen(false);
            debouncedApiCall();
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    const viewProcessedFileHandler = () => {
        const data = {
            s3_key,
            logoUrl: '',
            customerName: accountName,
            fileName: file_name,
        };
        viewProcessedFile(data);
    };

    const processedDownloadHandler = () => {
        const data = {
            s3_key,
            logoUrl: '',
            customerName: accountName,
            fileName: file_name,
        };
        batchDownloadFiles([data]);
    };

    return (
        <div className='d-flex align-items-center' style={{ gap: 8 }}>
            {tableId === TABLE_ID.SAFETY_MEETINGS && (
                <span title={'Edit'} id='action-btn' onClick={() => setShowEditModal(true)}>
                    <Edit />
                </span>
            )}
            {tableId === TABLE_ID.ASSIGNED_SAFETY_MEETINGS && hasPrivileges && (
                <span title={'View'} id='action-btn' onClick={viewHandler}>
                    <ViewIcon />
                </span>
            )}
            {tableId === TABLE_ID.ASSIGNED_SAFETY_MEETINGS && isUser && (
                <span title={'View'} id='action-btn' onClick={viewProcessedFileHandler}>
                    <ViewIcon />
                </span>
            )}
            {tableId === TABLE_ID.ASSIGNED_SAFETY_MEETINGS && isUser && (
                <span title={'Download'} id='action-btn' onClick={processedDownloadHandler}>
                    <DownloadIcon />
                </span>
            )}
            {tableId === TABLE_ID.ASSIGNED_SAFETY_MEETINGS && hasPrivileges && (
                <span title={'Download'} id='action-btn' onClick={downloadHandler}>
                    <DownloadIcon />
                </span>
            )}
            {tableId === TABLE_ID.SAFETY_MEETINGS && (
                <span title={'Replace'} id='action-btn' onClick={() => setShowReplaceModal(true)}>
                    <ReplaceIcon size={16} />
                </span>
            )}
            {tableId === TABLE_ID.SAFETY_MEETINGS && isAdmin && (
                <span title={'Delete'} id='action-btn' onClick={() => setIsDeleteModalOpen(true)}>
                    <Delete />
                </span>
            )}
            {showEditModal && hasPrivileges && (
                <AddSafetyMeetingModal
                    isEdit={true}
                    showAddModal={showEditModal}
                    setShowAddModal={setShowEditModal}
                    editHandler={editModalOnSubmit}
                    editDetails={{
                        file_name,
                        industries: JSON.parse(industries).map((i) => ({ label: i, value: i })),
                        language: languageOptions.find((l) => l.value === language),
                        type: { label: s3_key.split('/')[1], value: s3_key.split('/')[1] },
                    }}
                />
            )}
            <DeleteConfirmationModal
                isDeleteModal={isDeleteModalOpen}
                setIsDeleteModal={setIsDeleteModalOpen}
                selectedItemId={id}
                handleDeleteClick={deleteHandler}
                deleteButtonName={`${file_name} Templates`}
            />
            {showReplaceModal && (
                <ReplaceSafetyMeetingModal
                    showModal={showReplaceModal}
                    setShowModal={setShowReplaceModal}
                    id={id}
                    fileName={file_name}
                    debouncedApiCall={debouncedApiCall}
                />
            )}
        </div>
    );
};
