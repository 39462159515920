import { useCallback, useState } from 'react';
import useWindowEvent from './useWindowEvent';

const eventListenerOptions = {
    passive: true,
};

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });

    const setSize = useCallback(() => {
        setWindowSize({
            width: window.innerWidth || 0,
            height: window.innerHeight || 0,
        });
    }, []);

    useWindowEvent('resize', setSize, eventListenerOptions);
    useWindowEvent('orientationchange', setSize, eventListenerOptions);

    return windowSize;
};

export default useWindowSize;
