import React, { useMemo } from 'react';
import { removeSafetyMeeting, addSafetyMeeting } from '../../../redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ROLES } from '../../../constants';

export const SafetyMeetingCheckbox = ({ data }) => {
    const { id, s3_key, file_name } = data;

    const dispatch = useDispatch();
    const selectedSafetyMeetings = useSelector((s) => s.safetyMeetings.selectedSafetyMeetings);
    const hasChecked = useMemo(
        () => selectedSafetyMeetings?.map((item) => item.id).includes(id),
        [selectedSafetyMeetings],
    );
    const currentRole = useSelector((s) => s.user.role);
    const hasPrivileges = [
        ROLES.ADMIN,
        ROLES.CONTENT_MANAGER,
        ROLES.CSM,
        ROLES.SUPER_ADMIN,
        ROLES.USER,
    ].includes(currentRole);

    return (
        <div className='d-flex align-items-center' style={{ gap: 8 }}>
            {hasPrivileges && (
                <span
                    className='d-flex ms-1 align-items-center'
                    title={hasChecked ? 'Click to Deselect' : 'Click to Select'}
                    aria-label={'Select or deselect safety meetings'}
                >
                    <input
                        type='checkbox'
                        id='select-hc'
                        className='cursor-pointer'
                        checked={hasChecked}
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(addSafetyMeeting({ id, s3_key, file_name }));
                            } else {
                                dispatch(removeSafetyMeeting(id));
                            }
                        }}
                    />
                </span>
            )}
        </div>
    );
};
